<template>
  <div class="info">
    <div class="jobshwheader"></div>
    <div class="min_body">
      <div class="job_describe">
        <!-- 职位要求及待遇岗位 -->
        <div class="job_describe_top">
          <div class="jjxx">
            {{ info.Title }}
          </div>
        </div>
        <div class="sj_job_box mt10"></div>
        <div class="job_describe_cengter">
          <div class="user_undergo user_undergo_c">
            作者：{{ info.Staff_Name }} &nbsp;&nbsp;发布时间：{{
              info.Date_Op_Show
            }}
          </div>
          <div class="job_describe_cengter_header" v-html="info.Contents" />
          <img v-show="info.ImgSrc" :src="info.ImgSrc" />
          <div
            v-if="isFile"
            class="job_describe_cengter_header"
            style="color: red"
          >
            请在电脑端查看附件
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Base64 from '@/util/Base64'
  import { systemUrl } from '@/config'
  import { Toast } from 'vant'
  import { getNoticeInfo } from '@/api/workBench/notice/notice'
  import store from '@/store'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'NoticeInfo',
    data() {
      return {
        noticeId: null,
        info: {},
        isFile: false,
      }
    },
    created() {
      this.noticeId =
        this.$route.query.noticeId === undefined
          ? 0
          : Base64.decode(this.$route.query.noticeId)
      this.fetchData()
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'noticeInfo',
        '城管-通知公告详情',
        'zzdcg.yy.gov.cn/noticeInfo'
      )
    },
    methods: {
      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getNoticeInfo({
          NoticeId: String(this.noticeId),
        })
        if (data.ImgSrc != null) data.ImgSrc = systemUrl + data.ImgSrc
        this.info = data
        if (data.File_Path != null && data.File_Path != '') this.isFile = true
        Toast.clear()
      },
    },
  }
</script>

<style scoped>
  .job_describe_cengter {
    width: 95%;
    padding: 4%;
    border-bottom: 0.013333rem solid #eee;
  }

  .job_describe_cengter_header {
    font-size: 0.426667rem;
    color: #181818;
    ::v-deep img {
      max-width: 100%;
    }
  }

  .job_describe_top {
    position: relative;
    width: 94%;
    padding: 3%;
    font-size: 0.373333rem;
    background-color: #fff;
    border-bottom: 0.013333rem solid #eee;
  }
  .jjxx {
    position: relative;
    font-size: 0.5rem;
    font-weight: bold;
    text-align: center;
  }
  .jjxx::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    /*background: url(../../../assets/image/15687202109879_b.png) no-repeat;*/
    background-size: auto;
    background-size: 100%;
  }
  .job_describe {
    width: 100%;
    margin-top: -1.333333rem;
    overflow: hidden;
    line-height: 1.8;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
  .min_body {
    padding-right: 0.32rem;
    padding-left: 0.32rem;
  }
  .jobshwheader {
    width: 100%;
    height: 2.026667rem;
    font-size: 0.32rem;
    background-color: #2778f8;
  }
  .user_undergo {
    width: 100%;
    height: 0.8rem;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 0.8rem;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .user_undergo_c {
    position: relative;
    padding-left: 0rem;
    color: #9e9e9e;
    text-align: center;
  }
</style>
